import React, { useEffect, useState } from 'react';
import { Typography, Box, RadioGroup, FormControlLabel, Radio, TextField, Slider } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';

const SlideQuestions = ({
                            surveyData,
                            currentSlideIndex,
                            responses,
                            handleAnswerChange,
                            handleConfidenceChange,
                            handleCommentChange,
                            handleGeneralCommentChange,
                            handlePrevious,
                            handleNext,
                            handleSaveProgress,
                            handleDownloadAll,
                            isDownloadEnabled,
                        }) => {
    const currentSlide = surveyData.slides[currentSlideIndex];
    const [localResponses, setLocalResponses] = useState({});

    useEffect(() => {
        // Load existing responses for the current slide when the slide index changes
        if (responses && responses[currentSlideIndex]) {
            setLocalResponses(responses[currentSlideIndex]);
        } else {
            setLocalResponses({});
        }
    }, [currentSlideIndex, responses]);

    const handleRadioChange = (questionId, event) => {
        const value = event.target.value;
        const updatedResponse = {
            ...localResponses,
            [questionId]: {
                ...localResponses[questionId],
                answer: value,
            },
        };
        setLocalResponses(updatedResponse);
        handleAnswerChange(questionId, value);
    };

    const handleSliderChange = (questionId, _, newValue) => {
        const updatedResponse = {
            ...localResponses,
            [questionId]: {
                ...localResponses[questionId],
                confidenceLevel: newValue,
            },
        };
        setLocalResponses(updatedResponse);
        handleConfidenceChange(questionId, newValue);
    };

    const handleTextFieldChange = (questionId, event) => {
        const value = event.target.value;
        const updatedResponse = {
            ...localResponses,
            [questionId]: {
                ...localResponses[questionId],
                comment: value,
            },
        };
        setLocalResponses(updatedResponse);
        handleCommentChange(questionId, value);
    };

    const handleGeneralCommentFieldChange = (event) => {
        const value = event.target.value;
        const updatedResponse = {
            ...localResponses,
            generalComment: value,
        };
        setLocalResponses(updatedResponse);
        handleGeneralCommentChange(value);
    };

    // Determine whether to show the remaining questions based on the third question's answer
    const shouldShowRemainingQuestions = localResponses[surveyData.questions[2].id]?.answer === 'yes';

    return (
        <Box display="flex" flexDirection="column" height="100%">
            {/* Top Toolbar */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#ffffff',
                    zIndex: 10,
                    padding: '10px 0',
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Typography variant="h6">{currentSlide.SlideID}</Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrevious}
                        style={{ marginRight: '10px' }}
                        disabled={currentSlideIndex === 0}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ marginRight: '10px' }}
                        disabled={currentSlideIndex === surveyData.slides.length - 1}
                    >
                        Next
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProgress}
                        style={{ marginRight: '10px' }}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadAll}
                        disabled={!isDownloadEnabled}
                        startIcon={<DownloadIcon />}
                    >
                        Download
                    </Button>
                </Box>
            </Box>

            {/* Scrollable Content Area */}
            <Box flexGrow={1} overflow="auto" style={{ paddingRight: '10px' }}>
                {surveyData.questions.map((question, index) => {
                    const response = localResponses[question.id] || {};

                    // Always render the first three questions, and conditionally render the rest based on the third question's answer
                    if (index < 3 || shouldShowRemainingQuestions) {
                        return (
                            <Box key={question.id} mb={3}>
                                <Typography variant="body1" style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#000' }}>
                                    {question.questionText}
                                </Typography>
                                {question.questionDescription && (
                                    <Typography variant="body2" style={{ color: '#888' }}>
                                        {question.questionDescription}
                                    </Typography>
                                )}
                                {question.questionType === 'multiple-choice' && (
                                    <RadioGroup
                                        value={response.answer || ''}
                                        onChange={(e) => handleRadioChange(question.id, e)}
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                    >
                                        {question.options.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                                style={{ alignItems: 'flex-start', marginBottom: 5 }}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}

                                {question.questionType === 'yes-no' && (
                                    <RadioGroup
                                        value={response.answer || ''}
                                        onChange={(e) => handleRadioChange(question.id, e)}
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                    >
                                        {question.options.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                                style={{ alignItems: 'flex-start', marginBottom: 5 }}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                {index !== 99 && ( // Skip confidence and comments for the first yes/no question
                                    <>
                                        <Box display="flex" alignItems="center" mt={4} mb={4}>
                                            <Typography variant="body2" style={{ marginRight: '10px' }}>Confidence:</Typography>
                                            <Slider
                                                value={response.confidenceLevel !== undefined ? response.confidenceLevel : 0}
                                                min={0}
                                                max={10}
                                                step={1}
                                                onChange={(e, value) => handleSliderChange(question.id, e, value)}
                                                style={{ flexGrow: 1, marginRight: '20px' }}
                                            />
                                            <TextField
                                                value={response.confidenceLevel || ''}
                                                onChange={(e) => handleSliderChange(question.id, e, Number(e.target.value))}
                                                inputProps={{ min: 0, max: 10, type: 'number' }}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: '80px', border: '1px solid #ccc', paddingLeft: '10px' }}
                                            />
                                        </Box>
                                        <TextField
                                            placeholder="Comments"
                                            multiline
                                            rows={2}
                                            value={response.comment || ''}
                                            onChange={(e) => handleTextFieldChange(question.id, e)}
                                            variant="outlined"
                                            fullWidth
                                            style={{ marginTop: '10px', resize: 'both' }}
                                        />
                                    </>
                                )}
                            </Box>
                        );
                    }

                    return null;
                })}
                <Box mt={5}>
                    <Typography variant="h6">General Comments</Typography>
                    <TextField
                        placeholder="If there is any additional feedback, please enter here"
                        multiline
                        rows={4}
                        value={localResponses.generalComment || ''}
                        onChange={handleGeneralCommentFieldChange}
                        variant="outlined"
                        fullWidth
                        style={{ resize: 'both' }}
                    />
                </Box>
            </Box>

            {/* Bottom Toolbar */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#ffffff',
                    zIndex: 10,
                    padding: '10px 0',
                    borderTop: '1px solid #ddd',
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    Slide {currentSlideIndex + 1} of {surveyData.slides.length}
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProgress}
                        style={{ marginRight: '10px' }}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadAll}
                        disabled={!isDownloadEnabled}
                        startIcon={<DownloadIcon />}
                    >
                        Download
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default SlideQuestions;
