import React, { useEffect, useRef, useState } from 'react';
import OpenSeadragon from 'openseadragon';

const SlideSurveyImageViewer = ({ folderHandle, fileName }) => {
    const viewerRef = useRef(null);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const loadImage = async () => {
            if (folderHandle && fileName) {
                try {
                    const fileHandle = await folderHandle.getFileHandle(fileName, { create: false });
                    const file = await fileHandle.getFile();
                    const url = URL.createObjectURL(file);
                    setImageUrl(url);
                } catch (error) {
                    console.error('Failed to load image:', error);
                }
            }
        };

        loadImage();

        // Clean up the URL object when the component is unmounted or imageUrl changes
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [folderHandle, fileName]);

    useEffect(() => {
        if (!viewerRef.current || !imageUrl) return;

        // Initialize OpenSeadragon viewer
        const viewer = OpenSeadragon({
            element: viewerRef.current,
            tileSources: {
                type: 'image',
                url: imageUrl,  // The URL of the image created inside the component
            },
            prefixUrl: 'https://openseadragon.github.io/openseadragon/images/', // Prefix for OpenSeadragon buttons images
            showRotationControl: true,
            showFullPageControl: false,
            showHomeControl: true,
            showZoomControl: true,
            showSequenceControl: false,
            showFlipControl: true,
            showReferenceStrip: true,
            minZoomImageRatio: 0.5,
            maxZoomPixelRatio: 2,
            preserveViewport: true,
            visibilityRatio: 1,
        });

        // Clean up the viewer on component unmount
        return () => {
            if (viewer) {
                viewer.destroy();
            }
        };
    }, [imageUrl]);

    return (
        <div
            ref={viewerRef}
            style={{ width: '100%', height: '600px', backgroundColor: '#000' }}
        >
            {/* The OpenSeadragon viewer will be mounted here */}
        </div>
    );
};

export default SlideSurveyImageViewer;
