import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Box, FormControlLabel, Switch } from '@mui/material';
import SlideQuestions from './SlideQuestions';
import SlideSurveyImageViewer from './SlideSurveyImageViewer';

const App = () => {
    const [surveyData, setSurveyData] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [responses, setResponses] = useState({});
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
    const [folderHandle, setFolderHandle] = useState(null);
    const [leftPanelWidth, setLeftPanelWidth] = useState(60); // Start with 60% width for the left panel
    const [viewSlide, setViewSlide] = useState(true);
    const [viewSurvey, setViewSurvey] = useState(true);

    const handleLoadFolder = async () => {
        try {
            const selectedFolderHandle = await window.showDirectoryPicker();
            setFolderHandle(selectedFolderHandle);

            const surveyQuestionsFile = await selectedFolderHandle.getFileHandle('survey_questions.json');
            const surveySlidesFile = await selectedFolderHandle.getFileHandle('survey_slides.json');

            const surveyQuestionsData = await (await surveyQuestionsFile.getFile()).text();
            const surveySlidesData = await (await surveySlidesFile.getFile()).text();

            const questions = JSON.parse(surveyQuestionsData);
            const slides = JSON.parse(surveySlidesData);

            setSurveyData({ ...questions, slides });

            // Check if survey_responses.json exists and load it
            try {
                const surveyResponsesFile = await selectedFolderHandle.getFileHandle('survey_responses.json', { create: false });
                const surveyResponsesData = await (await surveyResponsesFile.getFile()).text();
                const savedResponses = JSON.parse(surveyResponsesData);
                setResponses(savedResponses); // Set the saved responses into state
                setIsDownloadEnabled(true);
            } catch (error) {
                console.log('No survey_responses.json file found, starting fresh.');
            }
        } catch (error) {
            console.error('Failed to load survey files:', error);
        }
    };

    const handleNext = () => {
        setCurrentSlideIndex((prevIndex) => Math.min(prevIndex + 1, surveyData.slides.length - 1));
    };

    const handlePrevious = () => {
        setCurrentSlideIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleAnswerChange = (questionId, value) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [currentSlideIndex]: {
                ...prevResponses[currentSlideIndex],
                [questionId]: {
                    ...prevResponses[currentSlideIndex]?.[questionId],
                    answer: value,
                    slideNumber: currentSlideIndex + 1,
                    questionNumber: questionId,
                },
            },
        }));
    };

    const handleConfidenceChange = (questionId, newValue) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [currentSlideIndex]: {
                ...prevResponses[currentSlideIndex],
                [questionId]: {
                    ...prevResponses[currentSlideIndex]?.[questionId],
                    confidenceLevel: newValue,
                },
            },
        }));
    };

    const handleCommentChange = (questionId, newComment) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [currentSlideIndex]: {
                ...prevResponses[currentSlideIndex],
                [questionId]: {
                    ...prevResponses[currentSlideIndex]?.[questionId],
                    comment: newComment,
                },
            },
        }));
    };

    const handleGeneralCommentChange = (newComment) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [currentSlideIndex]: {
                ...prevResponses[currentSlideIndex],
                generalComment: newComment,
            },
        }));
    };

    const handleSaveProgress = async () => {
        if (!folderHandle) return;

        const responseArray = surveyData.slides.map((slide, slideIndex) => {
            const slideResponses = responses[slideIndex] || {};
            const questions = surveyData.questions.map((question) => {
                const response = slideResponses[question.id] || {};
                return {
                    questionId: question.id,
                    questionTitle: question.questionTitle,
                    description: question.questionDescription,
                    answerChoice: response.answer || '',
                    confidence: response.confidenceLevel || '',
                    comment: response.comment || '',
                };
            });

            return {
                slideId: slide.SlideID,
                fileName: slide.FileName,
                questions,
                generalComment: slideResponses.generalComment || '',
            };
        });

        const responseJson = {
            slides: responseArray,
        };

        const fileHandle = await folderHandle.getFileHandle('survey_responses.json', { create: true });
        const writable = await fileHandle.createWritable();
        await writable.write(JSON.stringify(responseJson, null, 2));
        await writable.close();

        console.log('Progress saved:', responseJson);
        alert('Progress saved');
        setIsDownloadEnabled(true);
    };

    const handleDownloadAll = () => {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(responses, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "survey_responses.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const handleMouseDown = (e) => {
        const startX = e.clientX;
        const startLeftPanelWidth = leftPanelWidth;

        const handleMouseMove = (e) => {
            const deltaX = e.clientX - startX;
            const newLeftPanelWidth = Math.max(50, Math.min(70, startLeftPanelWidth + (deltaX / window.innerWidth) * 100));
            setLeftPanelWidth(newLeftPanelWidth);
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const currentSlide = surveyData ? surveyData.slides[currentSlideIndex] : null;

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f0f0',
                width: '100%',
                maxWidth: 'none',
                padding: 0,
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                    backgroundColor: '#e0e0e0',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                }}
            >
                <Typography variant="h4" component="h1" style={{ margin: 0 }}>
                    {surveyData ? surveyData.surveyTitle : "Burn Histology Survey Tool"}
                </Typography>
                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={viewSlide}
                                onChange={(e) => setViewSlide(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="View Slide"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={viewSurvey}
                                onChange={(e) => setViewSurvey(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="View Survey"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoadFolder}
                        style={{ marginRight: '10px' }}
                    >
                        Load Folder
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '10px' }}
                        disabled={!surveyData}
                    >
                        Survey Guide
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProgress}
                        style={{ marginRight: '10px' }}
                        disabled={!surveyData}
                    >
                        Save Progress
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadAll}
                        disabled={!isDownloadEnabled}
                    >
                        Download All
                    </Button>
                </Box>
            </Box>

            {surveyData ? (
                <Box style={{ display: 'flex', flexGrow: 1, padding: '0 20px' }}>
                    {viewSlide && (
                        <Box
                            style={{
                                width: viewSurvey ? `${leftPanelWidth}%` : '100%',
                                paddingRight: viewSurvey ? '10px' : 0,
                                overflow: 'hidden',
                                transition: 'width 0.2s',
                            }}
                        >
                            <SlideSurveyImageViewer
                                folderHandle={folderHandle}
                                fileName={currentSlide?.FileName}
                            />
                        </Box>
                    )}
                    {viewSlide && viewSurvey && (
                        <Box
                            onMouseDown={handleMouseDown}
                            style={{
                                width: '12px', // Increase width for easier grabbing
                                cursor: 'col-resize',
                                backgroundColor: '#ccc',
                            }}
                        />
                    )}
                    {viewSurvey && (
                        <Box
                            style={{
                                flexGrow: 1,
                                paddingLeft: viewSlide ? '10px' : 0,
                                overflowY: 'auto',
                                maxHeight: 'calc(100vh - 150px)',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SlideQuestions
                                surveyData={surveyData}
                                currentSlideIndex={currentSlideIndex}
                                responses={responses}
                                handleAnswerChange={handleAnswerChange}
                                handleConfidenceChange={handleConfidenceChange}
                                handleCommentChange={handleCommentChange}
                                handleGeneralCommentChange={handleGeneralCommentChange}
                                handlePrevious={handlePrevious}
                                handleNext={handleNext}
                                handleSaveProgress={handleSaveProgress}
                                handleDownloadAll={handleDownloadAll}
                                isDownloadEnabled={isDownloadEnabled}
                            />
                        </Box>
                    )}
                </Box>
            ) : (
                <Typography variant="h6" style={{ padding: '20px' }}>
                    Please load a survey folder to start.
                </Typography>
            )}
        </Container>
    );
};

export default App;
